import React, { useEffect, useState, useId, useMemo, useRef } from 'react';
import { Button, SwitchClient } from './api/ApiClient';
import classnames from "classnames";
import * as signalR from "@microsoft/signalr";
import NoSleepJs from "nosleep.js";

import audio1 from "./audio/1.mp3"
import audio2 from "./audio/2.mp3";
import audio3 from "./audio/3.mp3";

import "./App.scss"
import { API_BASE } from './api/ApiSettings';

const audioFiles = [
    new Audio(audio1),
    new Audio(audio2),
    new Audio(audio3)
];

const noSleep = new NoSleepJs();

function App() {


    const audio1Ref = useRef(null);
    const audio2Ref = useRef(null);
    const audio3Ref = useRef(null);

    const switchClient = new SwitchClient();
    const [currentPlayAudio, setCurrentPlayAudio] = useState(-1);
    const [currentLoadedAudio, setCurrentLoadedAudio] = useState(0);
    const [isOnline, setIsOnline] = useState(false);
    const [buttons, setButtons] = useState<Button[]>([]);
    const [buttonState, setButtonState] = useState<{ pin: number, state: boolean }[]>([]);
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const id = useId();

    const playerState = useMemo<number>(() => {
        if (!isOnline) {
            return 0;
        } else {
            return 2;
        }
    }, [currentLoadedAudio, isOnline])

    const fetchButtons = async () => {
        const data = await switchClient.getSettings();
        setButtons(data);
        var states = data.map<{ pin: number, state: boolean }>(x => {
            return { pin: x.programIndex, state: x.state }
        });
        setButtonState(states);
    }

    const fetchIsOnline = async () => {
        const data = await switchClient.isOnline();
        setIsOnline(data);
    };

    const createHubContext = async () => {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${API_BASE}/stateHub`)
            .withAutomaticReconnect()
            .build();
        await hubConnection.start();
        setConnection(hubConnection);
    }

    const preloadAudio = (audio: any) => {
        audio.oncanplaythrough = () => {
            setCurrentLoadedAudio(prevState => {
                return prevState + 1;
            })
        };
    }

    useEffect(() => {
        for (var i in audioFiles) {
            preloadAudio(audioFiles[i]);
        }

        fetchIsOnline().catch(console.error);
        fetchButtons().catch(console.error);
        createHubContext().catch(console.error);


    }, []);

    //const checkPlay = (pin: number, state: boolean) => {
    //    const audioFilesRef = [audio1Ref, audio2Ref, audio3Ref];


    //    var play = -1;
    //    if (pin == 3) {
    //        play = 0;
    //    } else if (data[4].state) {
    //        play = 1;
    //    } else if (data[5].state) {
    //        play = 2;
    //    } else if (!data[3].state && !data[4].state && !data[5].state) {
    //        play = -1
    //    }
    //    console.log('initial', play, currentPlayAudio);

    //    if (currentPlayAudio == play) {
    //        console.log('same playing return');
    //        return;
    //    }

    //    if (play > -1) {
    //        console.log('change play', play);
    //        if (currentPlayAudio > -1) {
    //            audioFiles[currentPlayAudio].pause();
    //            audioFiles[currentPlayAudio].currentTime = 0;

    //        }
    //        setCurrentPlayAudio(play);
    //        audioFiles[play].currentTime = 0;
    //        audioFiles[play].play();

    //    } else {
    //        console.log('stop all');
    //        audioFiles.forEach(x => {
    //            x.pause();
    //            x.currentTime = 0;
    //        });
    //        setCurrentPlayAudio(-1);
    //    }
    //}

    //useEffect(() => {
    //    checkPlay(buttonState);
    //}, [buttonState])

    useEffect(() => {
        if (connection) {
            connection.on("SetPin", (data: any) => {
                setButtonState(data);
            });

            connection.on("IsOnline", (onlineState: boolean) => {
                setIsOnline(onlineState);
            });
        }

    }, [connection])

    async function onChangeState(pin: number, value: boolean) {
        noSleep.enable();
        const audioFilesRef = [audio1Ref, audio2Ref, audio3Ref];
        if (pin >= 3 && pin < 6) {
            if (buttonState[pin].state != value) {
                if (value) {
                    audioFilesRef.forEach(c => {
                        (c.current as any).pause();
                        (c.current as any).currentTime = 0;
                    });

                    setCurrentPlayAudio(pin - 3);
                    (audioFilesRef[pin - 3].current as any).play();
                } else {
                    setCurrentPlayAudio(-1);
                    audioFilesRef.forEach(c => {
                        (c.current as any).pause();
                        (c.current as any).currentTime = 0;
                    });
                }

            }

        } else if (pin >= 0 && pin < 3) {
            setCurrentPlayAudio(-1);
            audioFilesRef.forEach(c => {
                (c.current as any).pause();
                (c.current as any).currentTime = 0;
            });
        } else {
            console.log(audioFilesRef[pin - 3])
            if (currentPlayAudio > -1) {

                    (audioFilesRef[currentPlayAudio].current as any).play();
            }
           
        
        }
        await switchClient.setPin(pin, value);
       

    }

    return (
        <>
            {playerState === 2 && <div className='mainContainer'>
                {buttonState.map((button, index) => <div className='box'>
                    <button onClick={() => onChangeState(button.pin, !button.state)} className={classnames('btn w-100 h-100', { 'btn-light': !button.state, 'btn-primary': button.state })} type='button'>
                        {buttons[index]?.name}
                    </button>
                </div>)}
            </div>}
            {playerState === 1 && <div className='mainContainer'>
                <div className="spinner-border" role="status" style={{ width: "5rem", height: "5rem" }} >
                </div>
            </div>}
            {playerState === 0 && <div className='mainContainer'>
                <div className="alert alert-danger" role="alert">
                    Fontanna jest offline
                </div>
            </div>}
            <audio src={audio1} ref={audio1Ref} />
            <audio src={audio2} ref={audio2Ref} />
            <audio src={audio3} ref={audio3Ref} />
        </>
    );
}

export default App;
